import apiClient from '@/api/apiClient';
import CustomError from '@/entities/customError';
import { PaymentMethodSetting, ComparePaymentsShema } from '@/services/services.types';

class PaymentsService {
    /** Возвращает список платежных систем, которые можно использовать */
    async getImplimentedMethod(): Promise<[CustomError | null, string[]]> {
        const url = `/configs/payments-implemented-methods`;
        const defaultValue: string[] = [];
        const errorPath = '[api:payment:getImplimentedMethod]';
        return await apiClient.admin.get({ url, defaultValue, errorPath });
    }

    /** Возвращает список параметров для конкретной платежной системы */
    async getPaymentSettings(method: string) {
        const url = `/configs/payment-settings/${method}`;
        const defaultValue = {};
        const errorPath = '[api:payment:getPaymentSettings]';
        return await apiClient.admin.get({ url, defaultValue, errorPath });
    }

    /** Возвращает список параметров для списка платежной системы  */
    async getPaymentsSettings(methods: string[]) {
        const data = await Promise.all(methods.map((method) => this.getPaymentSettings(method)));
        const result = {} as { [key: string]: any };
        methods.forEach(async (method, index) => {
            const [error, settings] = data[index];
            if (error) error.notify();
            result[method] = settings;
        });
        return result;
    }

    /** Сравниваем список полей платежной системы из конфига со списком текущих полей */
    comparePaymentsShema(config: any, settings: PaymentMethodSetting[]) {
        settings.push({ name: 'isActive', type: 'bool' }); // временный костыль
        const result: ComparePaymentsShema = {
            isValid: true,
            newFields: [],
        };
        if (!settings) return result;
        // проверяем наличие и тип всех settings в конфиге, если поля нет, запоминаем его
        settings.forEach((setting) => {
            const prop = config[setting.name];
            // if (!(prop && prop.type === setting.type)) {
            if (!prop) {
                result.isValid = false;
                result.newFields.push(setting);
            }
        });
        return result;
    }
}

export default new PaymentsService();
